import React, { useState, useReducer, useEffect, Fragment } from 'react';
import Dialog from '@common/Dialog';
import { Grid } from '@material-ui/core';
import classnames from 'classnames';
import Avaliation from './Avaliation';
import CommonForm from '@common/CommonForm2';
import { Avatar, TextFields } from '@material-ui/core';
import Button from '@common/Button';
import BarBlue from '@images/BarBlue.png';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AttachFileImage from '@images/attachFile.png';
import { Tooltip } from 'react-tippy';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import './DialogForm.css';
const crypto = require('crypto');
import KnowledgeBase from '@images/svg-icon/KnowledgeBase';
import SolutionContainer from '../solution/SolutionContainer';
import Store from '@data/Store';
import { applyTimezoneOnHTML } from '../../common/Timezone';
import decrypt from '../../util/decrypt';
import moment from 'moment';
import { concatDateHour } from '../../util/dateTime';

let initialCatalogTask = undefined;
let currentCatalogTask = undefined;

export default function ({
  open,
  onClose,
  store,
  action,
  APIEndpoints,
  values,
  onFormSubmitTab,
  ondonwFile,
  subTicketCreated,
  crisisOption,
  htmlValues,
  onUpdateTemplateHtml,
}) {
  let {
    users,
    tickets_types_cat,
    chiefs,
    groupstech,
    groups,
    language,
    taskcatalog,
    priorities,
    impacts,
    urgencies,
    slas,
    view,
  } = store;
  const reducer = (state, value) => ({ ...state, ...value });
  let [anchorEl, setAnchoEl] = useState(null);
  let open_ = !!anchorEl;
  let [techname, setTechname] = useState('');
  const [generalParams, setGeneralParams] = useState([]);
  const handleClick = (event) => setAnchoEl(event.currentTarget);
  let [ds, SetDs] = useState(true);

  let [formsTemplate, setFormsTemplate] = useState([]);

  let [catalogs, setCatalogs] = useState([]);
  let [categories, setCategories] = useState([]);

  let [servies, setServices] = useState([]);

  const handleClose = () => setAnchoEl(null);
  let [form1, setForms1] = useState(null);
  let [form2, setForms2] = useState(null);
  let [form3, setForms3] = useState(null);
  let [form4, setForms4] = useState(null);
  let [form5, setForms5] = useState(null);
  let [enableTypeTicketCrisis, setEnableTypeTicketCrisis] = useState(false);

  let [userDetail, setuserDetail] = useState({});
  let [contracts, setContracts] = useState([]);
  let [companies, setCompanies] = useState([]);
  let [solicitantGlobal, setSolicitantGlobal] = useState(false);
  let [ticketavaliation, setTicketavaliation] = useState([]);
  let [deviceOptions, setDeviceOptions] = useState([]);
  let [departmentOptions, setdepartmentOptions] = useState([]);
  let [responsibleOptions, setresponsibleOptions] = useState([]);
  const [openKnowledge, setOpenKnowledge] = useState(false);
  let [groupcat, setgroupcat] = useState([]);
  let [techGroupOptions, settechGroupOptions] = useState([]);
  let [solicitantOptions, SetsolicitantOptions] = useState([]);
  let [cleanValueSolicitant, setCleanValueSolicitant] = useState(false);
  const [requesterId, setRequesterId] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [templateFormOriginal, setTemplateFormOriginal] = useState('');

  let [form_, setForm_] = useReducer(reducer, {
    company: '',
    contract: '',
    category: '',
    catalog: '',
    user: '',
    device: '',

    catalog_task: '',
    id_grouptech: 0,
    devicesUser: [],
    categoryOptions: [{ value: 0, label: '' }],
    iddep: null,
  });

  let [form, setForm] = useReducer(reducer, {
    type_tickets: null,
    fk_id_company: null,
    fk_id_device: null,
    namesponsor: '',
    fields: [],
    fk_id_contract: null,
    fk_id_user: null,
    fk_id_category: null,
    fk_id_catalog_service: null,
    fk_id_catalog_task: null,
    fk_id_group_tech: null,
    fk_id_tech: null,
    fk_id_department: null,
    fk_id_user_sponsor: null,
    fk_id_priority: null,
    fk_id_urgency: null,
    subject: null,
    fk_id_impact: null,
    fk_id_complexity: null,
    recurrent: 1,
    description: null,
    fk_id_request: null,
    fk_id_tech: null,
    files: [],
    filesrequest: [],
    ticketArray: undefined,
    req: [],
    type: '',
    template: null,
    dt_recurrence: null,
    time_recurrence: null,
  });

  const [prevTypeTickets, setPrevTypeTickets] = useState(null);
  const [prevCompany, setPrevCompany] = useState(null);
  const [prevCategory, setPrevCategory] = useState(null);
  const [prevSubcategory, setPrevSubcategory] = useState(null);

  const devices = (user) => {
    action
      .execute('get', APIEndpoints.DEVICE + 'user/' + user, 'devices')
      .then((h) => {
        setForm_({ devicesUser: h.data[0] });
      });
    setForm_({ user: user });
  };

  useEffect(() => {
    setFormsTemplate('');
  }, [form.type_tickets]);

  const deviceContractSolicitant = (id_contract, id_user) => {
    if ((id_contract || form_.contract) > 0 && (id_user || form.fk_id_user) > 0)
      action
        .execute(
          'get',
          APIEndpoints.DEVICE +
          '/contract/' +
          (id_contract || form_.contract) +
          '/' +
          (id_user || form.fk_id_user),
        )
        .then((h) => {
          setDeviceOptions(
            h.data.map((x) => ({ value: x.id_device, label: x.name })),
          );
        });

    // let userSelect = users.filter(c => c.id_user === id_user)[0];
    // setuserDetail(userSelect)
  };
  const catalogGroup = (id) => {
    //findAllcat
    action
      .execute('get', APIEndpoints.CATALOG + '/group/' + id, '')
      .then((b) => {
        setgroupcat(b.data[0]);
      });
  };

  const userDetailFunc = (id_user) => {
    action
      .execute('get', APIEndpoints.TICKET + '/userdetail/' + id_user, '')
      .then((b) => {
        setuserDetail(b.data);
      });
  };

  const catalogCompany = (company, v) => {
    if (company && v) {
      action
        .execute(
          'get',
          `${APIEndpoints.CATALOG}/company/${company}/${v}?requesterId=${requesterId}&origin=ticket`,
          ''
        )
        .then((b) => {
          setCatalogs(b.data);
        });
    } else if (v) {
      action
        .execute(
          'get',
          `${APIEndpoints.CATALOG}/company/0/${v}?requesterId=${requesterId}&origin=ticket`,
          '')
        .then((b) => {
          setCatalogs(b.data);
        });
    }
  };

  const categoryCompany = (company) => {
    if (company) {
      action
        .execute('get', APIEndpoints.CATEGORY + '/company/' + company, '')
        .then((b) => {
          setCategories(b.data);
        });
    } else {
      // action.execute('get', APIEndpoints.CATEGORY, '').then((b) => {
      //  setCategories(b.data);
      //  });
    }
  };

  const chiefDepartment = (id_user) => {
    if (userSelect) {
      action
        .execute(
          'get',
          APIEndpoints.DEPARTMENT +
          '/chief/' +
          id_user +
          '/' +
          (form_.company == '' ? form_.fk_id_company : form_.company),
        )
        .then((h) => {
          setresponsibleOptions(
            h.data.map((x) => ({ value: x.id_user, label: x.name })),
          );
          setdepartmentOptions(
            h.data.map((x) => ({
              value: x.id_department,
              label: x.namedepartment,
            })),
          );

          setForm({ fk_id_department: h.data[0]?.id_department });
          setForm({ fk_id_user_sponsor: h.data[0]?.id_user });
          setForm({ namesponsor: h.data[0]?.name });
        });
    }
  };
  const usersCompany = (id_company) => {
    action
      .execute('get', APIEndpoints.USER + 'company/' + id_company)
      .then((h) => {
        SetsolicitantOptions(
          h.data
            .filter((b) => b.name != '')
            .map((x) => ({
              value: x.id_user,
              label: x.name,
              fk_id_department: x.fk_id_department,
              role: x.role,
            })),
        );
      });
  };

  /**api/department/chief/:id/:company */

  const decryp = (aMsg = '') => {
    try {
      var aSecret = 'Orch3str0!@forever!';

      aMsg = aMsg.replace(/\s/g, '');
      let decipher, tRet;
      decipher = crypto.createDecipher('aes-256-cbc', aSecret);
      tRet = decipher.update(
        (aMsg || '').replace(/\s/g, '+'),
        'base64',
        'utf8',
      );
      tRet += decipher.final('utf8');

      return tRet;
    } catch (e) {
      return {};
    }
  };

  const onFormSubmitFields = () => {
    const fields = [];
    let campossize = false;
    let camposobrigatorios = false;
    let camposobrigatoriosradio = false;
    let camposobrigatorioscheck = false;

    if ($('#template-recategorization').html() != undefined) {
      $('#template-recategorization textarea').each(function () {
        const textarea = $(this);
        textarea.attr('value', textarea.val());
        textarea.html(textarea.val());

        fields.push({
          name: textarea.attr('name'),
          value: textarea.val(),
        });

        const label = $(this).parent().find('label');
        if (label.text().split('*')[1] != undefined) {
          if (label.text().split('*')[1].length >= 0) {
            if (!textarea.val()) {
              camposobrigatorios = true;
              textarea.css('border-color', 'red');
            } else {
              textarea.css('border-color', 'var(--primary)');
            }
          }
        }
      });

      $('#template-recategorization input[type!=hidden]').each(function () {
        if ($(this).attr('type') == 'radio') {
          // $(this).removeAttr('checked');

          if ($(this).is(':checked')) {
            $(this).attr('checked', true);
            fields.push({
              name: $(this).attr('name').replace('-recategorizacao', ''),
              value: $(this).attr('value'),
            });
          }

          let ip = $(this);
          $('#template-recategorization label').each(function () {
            var for_ = '';
            try {
              for_ = $(this).attr('for');
            } catch (e) { }
            if (for_ === ip.attr('name-recategorizacao')) {
              if ($(this).text().split('*')[1] != undefined) {
                if ($(this).text().split('*')[1].length >= 0) {
                  camposobrigatoriosradio = true;
                  $('[name=' + for_ + '-recategorizacao' + ']').each(function () {
                    try {
                      if ($(this).is(':checked')) {
                        camposobrigatoriosradio = false;
                      }
                    } catch (e) { }
                  });
                  $(this).css('color', camposobrigatoriosradio ? 'red' : 'var(--primary)');
                }
              }
            }
          });
        } else if ($(this).attr('type') == 'checkbox') {
          if (
            $('input[id=' + $(this).attr('id') + ']:checked').attr('id') ==
            $(this).attr('id')
          ) {
            $(this).attr('checked', true);
            fields.push({
              name: $(this).attr('name'),
              value: $(this).attr('value'),
            });
          } else {
            $(this).removeAttr('checked');
          }
          let ip = $(this);
          $('#template-recategorization  label').each(function () {
            var for_ = ' ';
            try {
              for_ = $(this).attr('for');
            } catch (e) { }

            if (ip.attr('name').replace('-recategorizacao', '').indexOf(for_) > -1) {
              if ($(this).text().split('*')[1] != undefined) {
                if ($(this).text().split('*')[1].length >= 0) {
                  camposobrigatorioscheck = true;
                  $('#template-recategorization  input').each(function ()  {
                    try {
                      if ($(this).attr('name').indexOf(for_) > -1) {
                        try {
                          if ($(this).attr('checked')) {
                            camposobrigatorioscheck = false;
                          }
                        } catch (e) { }
                      }
                    } catch (e) { }
                  });
                  $(this).css('color', camposobrigatorioscheck ? 'red' : 'var(--primary)');
                }
              }
            }
          });
        } else if ($(this).attr('type') == 'datetime-local') {
          $(this).attr('value', $(this).val());
          const userLogin = Store.getUserLoged();
          const timezone = userLogin?.timezone?.value;

          fields.push({
            name: $(this).attr('name') || $(this).attr('id'),
            value: moment($(this).val()).tz(timezone).utc().toISOString(),
          });
          let ip = $(this);
          $('#template-recategorization label').each(function () {
            if ($(this).attr('for') === ip.attr('id')) {
              if ($(this).text().split('*')[1] != undefined) {
                if ($(this).text().split('*')[1].length >= 0) {
                  if (ip.attr('value').length == 0) {
                    ip.css('border-color', 'red');
                    camposobrigatorios = true;
                  } else {
                    ip.css('border-color', 'var(--primary)');
                  }
                }
              }
            }
          });
        } else {
          $(this).attr('value', $(this).val());
          fields.push({
            name: $(this).attr('name') || $(this).attr('id'),
            value: $(this).attr('value'),
          });
          let siz = $(this).attr('max');
          let min = $(this).attr('min');
          if (siz) {
            if (
              parseInt($(this).val().length) > parseInt(siz) ||
              parseInt($(this).val().length) < parseInt(min)
            ) {
              $(this).css('border-color', 'red');
              $(this).before(
                `<label  id="formtemplabel__" style='color:red'>  ${min || 0
                } á ${siz || 0} caracteres</label>`
              );
              campossize = true;
            }
          }

          let ip = $(this);
          $('#template-recategorization label').each(function () {
            if ($(this).attr('for') === ip.attr('id')) {
              if ($(this).text().split('*')[1] != undefined) {
                if ($(this).text().split('*')[1].length >= 0) {
                  if (ip.attr('value').length == 0) {
                    ip.css('border-color', 'red');
                    ip.parent().find('fieldset').css('border-color', 'red');
                    camposobrigatorios = true;
                  } else {
                    ip.css('border-color', 'var(--primary)');
                    ip.parent().find('fieldset').css('border-color', 'var(--primary)');
                  }
                }
              }
            }
          });
        }
      });

      $('#template-recategorization select').each(function () {

         const isMultiple = $(this).attr('multiple') !== undefined;
         const selectedValues = isMultiple ? $(this).val() : $(this).val() || "";

         const label = $(this).parent().find('label');
         if (label.text().split('*')[1] != undefined) {
               if (label.text().split('*')[1].length >= 0) {
                  if (isMultiple ? selectedValues.length === 0 : !selectedValues) {
                     camposobrigatorios = true;
                     $(this).css('border-color', 'red');
                  } else {
                     $(this).css('border-color', 'var(--primary)');
                  }
               }
         }

         fields.push({
               name: $(this).attr('name') || $(this).attr('id'),
               value: selectedValues
         });
      });

      $('#template-recategorization label').each(function () {
        try {
          if ($(this).attr('for').indexOf('textarea') > -1) {
            if ($(this).text().split('*')[1] != undefined) {
              if ($(this).text().split('*')[1].length >= 0) {
                // alert($('#'+$(this).attr('for')).text())

                let node_ = $(this)
                  .parent()
                  .parent()
                  .nextAll()
                  .slice(2, 3)
                  .children()[0];
                if (
                  (node_ &&
                    node_.firstChild.tagName == 'P' &&
                    node_.firstChild.textContent.length == 0) ||
                  (!node_ && $('#' + $(this).attr('for')).text().length == 0)
                ) {
                  camposobrigatorios = true;
                }
              }
            }
          }
        } catch (e) { }
      });
    }

    if (
      camposobrigatorios == false &&
      campossize == false &&
      camposobrigatorioscheck == false &&
      camposobrigatoriosradio == false
    ) {
      const ticketUpdated = { ...values };
      ticketUpdated.fields = fields;
      ticketUpdated.template = templateFormOriginal;

      // createRandomToken($('#template').html());
      try {
        onUpdateTemplateHtml(ticketUpdated);
        store.toggleAlert(true, language.UPDATED_FIELDS, 'success');
      } catch {

      }
      return true;
    } else {
      if (
        camposobrigatorios ||
        camposobrigatoriosradio ||
        camposobrigatorioscheck
      )
        store.toggleAlert(true, language.INFORM_REQUIRED_FIELDS, 'error');

      if (campossize)
        store.toggleAlert(true, language.CHECK_FIELD_SIZES, 'error');
    }
  }

  const onFormSubmitTab_ = () => {
    SetDs(false);
    setTimeout(() => {
      SetDs(true);
    }, 4000);

    const fieldsSavedSuccessfully = onFormSubmitFields();
    if (fieldsSavedSuccessfully) {
      setTimeout(
        function () {
          try {
            form1.submit();
            form2.submit();
            form3.submit();
            form4.submit();
          } catch (err) { }

          try {
            if (values.ticketArray.length > 1) {
              form.fk_id_device = 0;
              form.ticketArray = values.ticketArray;
              form.type = 'ticket';
            }
          } catch (e) {
            form.ticketArray = [];
          }
          try {
            if (values.arraytk.length > 1) {
              form.fk_id_device = 0;
              form.ticketArray = values.arraytk;
              form.type = 'ticket';
            }
          } catch (e) {
            form.ticketArray = [];
          }

          if (
            form.fk_id_catalog_service == null ||
            form.fk_id_catalog_task == null ||
            form.fk_id_group_tech == null
          ) {
            store.toggleAlert(true, language.FILL_IN_ALL_FIELDS, 'error');
          } else {
            form.id_tickets = values.id_tickets;
            if (subTicketCreated) {
              form.id_tickets = subTicketCreated.id_tickets;
            }
            form.sub = values.sub;
            
            onFormSubmitTab({ ...form, ...form_, 
               fk_id_company: form.fk_id_company, 
               dt_start_crisis: form.dt_start_crisis ? concatDateHour(form.dt_start_crisis, form.dt_starttime_crisis) : null
             });
            initialCatalogTask = currentCatalogTask;
          }
        }.bind(this),
        2000,
      );
    }
  };
  const GC = ({ items, labelProps = {}, valueProps = {} }) => (
    <div className="grid-container">
      {items.map((e, i) => (
        <Fragment>
          <div
            {...labelProps}
            {...e.labelProps}
            className={classnames(
              'grid-columns label',
              labelProps.className,
              (e.labelProps || {}).className,
            )}
          >
            {e.label}
          </div>
          <div
            {...valueProps}
            {...e.valueProps}
            className={classnames(
              'grid-columns value',
              valueProps.className,
              (e.valueProps || {}).className,
            )}
          >
            {e.value}
          </div>
        </Fragment>
      ))}
    </div>
  );

  useEffect(() => {
    action.execute('get', APIEndpoints.GENERAL_PARAMETERS, '').then((res) => {
      setGeneralParams(res.data);
    });
  }, []);

  useEffect(() => {
    checkIfCurrentUserCanCreateCrisisTicket();
  }, [generalParams]);

  useEffect(() => {
    if (!initialCatalogTask && values.fk_id_catalog_task) {
      initialCatalogTask = values.fk_id_catalog_task;
      currentCatalogTask = initialCatalogTask;
    }
  }, values.fk_id_catalog_task);

  useEffect(() => {
    if (
      values.fk_id_user == store.getUserLoged().id &&
      values.fk_id_catalog_service > 0 &&
      store.getUserLoged().role != 'client'
    ) {
      action.execute('get', APIEndpoints.TICKET + '/catalog').then((v) => {
        let cat_service = v.data.map((g) => g.id_catalog_service);
        if (cat_service.includes(values.fk_id_catalog_service)) {
        } else {
          store.toggleAlert(
            true,
            language.THE_TECHNICIAN_NOT_ALLOWED_CATEGORIZE_CALLS_GROUP_THAT_DOES_NOT_BELONG,
            'error',
          );
          setTimeout(() => {
            onClose();
          }, 4000);
        }
      });
    }

    if (contract.length == 0) {
      action
        .execute(
          'get',
          APIEndpoints.CONTRACT + 'companylow/' + values.fk_id_company,
        )
        .then((i) => {
          setContracts(i.data);
        });
    }
    if (companies.length == 0) {
      try {
        action
          .execute('get', APIEndpoints.COMPANY, 'companies')
          .then((item) => {
            setCompanies(item.data);
          });
      } catch (e) {
        setCompanies([]);
      }
    }

    let params = JSON.parse(decryp(sessionStorage.getItem('params')));
    action.execute('get', APIEndpoints.GROUP, 'groups');
    action.execute('get', APIEndpoints.TABLE_STRUCTURED + '/40', 'urgencies');
    action.execute('get', APIEndpoints.TABLE_STRUCTURED + '/60', 'priorities');
    action.execute(
      'get',
      APIEndpoints.TABLE_STRUCTURED + '/70',
      'type_tickets',
    );

    action.execute('get', APIEndpoints.TABLE_STRUCTURED + '/50', 'impacts');
    action.execute('get', APIEndpoints.SLA, 'slas');

    action.execute('get', APIEndpoints.USER + '/view', 'view');
    setForm({ type_tickets: values.id_type });
    setForm({ req: values.req });
    setForm_({ recurrent: 1 });

    devices(values.fk_id_user);

    setForm({ fk_id_device: values.fk_id_device });
    setForm({ fk_id_contract: values.fk_id_contract });
    setForm({ fk_id_group_tech: values.fk_id_group_tech });
    setForm({ fk_id_company: values.fk_id_company });
    setForm_({ fk_id_company: values.fk_id_company });

    setForm({ fk_id_user: values.fk_id_user });
    setForm({ subject: values.subject });
    setForm({ description: values.description });
    setForm({ fk_id_category: values.fk_id_category });
    setForm({ fk_id_catalog_service: values.fk_id_catalog_service });
    setForm({ fk_id_catalog_task: values.fk_id_catalog_task });
    chiefDepartment(values.fk_id_user);
    if (values.fk_id_company > 0 && values.fk_id_category > 0) {
      // catalogCompany(values.fk_id_company, values.fk_id_category)
    }
    if (values.fk_id_catalog_service > 0) {
      catalogGroup(values.fk_id_catalog_service);
    }

    if (values.fk_id_catalog_task > 0) {
      getFormCatalog(values.fk_id_catalog_task);
    }

    if (values.fk_id_company > 0) {
      usersCompany(values.fk_id_company);
    }

    deviceContractSolicitant(values.fk_id_contract, values.fk_id_user);

    var items =
      values.files != undefined
        ? values.files.map((f) => ({
          name: f.split('|')[0] + ' - ' + moment(f.split('|')[3]).format('L'),
          size: '',
          type: '',
          nn: f.split('|')[0],
          id_tickets_file: f.split('|')[2],
          fk_id_request: values.fk_id_request,
          user_cad: '',
          id_user: '',
        }))
        : null;
    if (items) {
      setForm({ filesrequest: items });
    }

    try {
      if (params[0].ticket_contract_solicitant == true) {
        setSolicitantGlobal(true);
      }
    } catch (e) { }
    catalogCompany(values.fk_id_company, values.fk_id_category);

    setRequesterId(values.fk_id_user);

    if (values.fk_id_request > 0) {
      categoryCompany(values.fk_id_company);
      setTimeout(() => {
        if (values.fk_id_category > 0) {
          catalogCompany(values.fk_id_company, values.fk_id_category);
          //  catalogCompany(form_.company,v)
        }
      }, 2000);

      if (params[0].avaliation_tickets == true) {
        action
          .execute(
            'post',
            APIEndpoints.TICKET + '/ticketFind',
            '',
            { avaliationticketunique: true },
            {},
            null,
            null,
          )
          .then((g) => {
            if (g.data.length > 0) {
              setTicketavaliation(true);
            }
          });
      }
    }

    try {
      chiefDepartment(values.fk_id_user);
    } catch (e) { }
  }, []);

  useEffect(() => {
    if (values.fk_id_catalog_service > 0) {
      let t = catalogs
        .filter((c) => c.id_catalog_service === values.fk_id_catalog_service)
        .map((t) => t.id_group_users);

      let itens = groups
        .filter((g) => g.id_group_users == t[0])
        .map((tec) => ({ value: tec.id_group_users, label: tec.name }));

      let itens_ = groups
        .filter((g) => g.type == 1 && g.type_tech == 1)
        .map((tec) => ({ value: tec.id_group_users, label: tec.name }));

      settechGroupOptions(itens);

      settechGroupOptions(itens_);

      try {
        setForm({ fk_id_group_tech: itens[0].value });
        setForm_({ id_grouptech: itens[0].value });
        setgroupcat({ name: itens[0].label });
      } catch (e) {
        setForm({ fk_id_group_tech: 0 });
        setForm_({ id_grouptech: 0 });
        setgroupcat({ name: '' });
      }
    } else {
      let itens_ = groups
        .filter((g) => g.type == 1 && g.type_tech == 1)
        .map((tec) => ({ value: tec.id_group_users, label: tec.name }));
      settechGroupOptions(itens_);
    }
  }, [catalogs]);

  useEffect(() => {
    getFormCatalog(values.fk_id_catalog_task);
  }, [htmlValues]);


  const getFormCatalog = (id) => {
    action
      .execute('get', APIEndpoints.TASK + '/getformticket/' + id, '')
      .then((g) => {
        if (
          g.data &&
          g.data.length > 0
        ) {
          setTemplateFormOriginal(g.data[0].html);

          let c = 0;
          let item = g.data.map((obj) => {
            c = c + 1;
            let id = 'ID' + c;

            return (
              <div className="contain">
                <div className="itemTicketDetail">
                  <p style={{ color: '#888080' }} id={id}>
                    {formshtmlWithEdit(decrypt(obj.html))}
                  </p>
                </div>
                <div style={{ marginTop: '30px' }} />
                <div>
                  {obj.user_up != null
                    ? language.LAST_UPDATE +
                    obj.user_up +
                    '-' +
                    moment(obj.dt_up).format('DD-MM-YY HH:mm:ss')
                    : null}
                </div>
                <hr />
              </div>
            );
          });

          try {
            setFormsTemplate(item[0]);
          } catch (e) {
            console.log(e);
          }
        } else {
          setFormsTemplate('')
          setTemplateFormOriginal('');
        }
      });
  };

  const companyallContract = (id) => {
    action
      .execute('get', APIEndpoints.CONTRACT + 'companylow/' + id)
      .then((i) => {
        setContracts(i.data);
      });
  };

  const checkIfCurrentUserCanCreateCrisisTicket = () => {
    const currentUser = store.getUserLoged();
    const enabledInParams = generalParams.enable_crisis_ticket_type;
    const isRoleToEditCrisis =
      currentUser.role === 'tech' ||
      currentUser.role === 'scm' ||
      currentUser.scm ||
      currentUser.role === 'admin' ||
      currentUser.role === 'client';

    if (!enabledInParams || !isRoleToEditCrisis) {
      setEnableTypeTicketCrisis(false);
    } else {
      action
        .execute(
          'get',
          APIEndpoints.GENERAL_PARAMETERS +
          '/company/crisis/user/' +
          currentUser.id,
        )
        .then((i) => {
          setEnableTypeTicketCrisis(i.data && i.data.length > 0);
        });
    }
  };

  useEffect(() => {
    if (prevTypeTickets && prevTypeTickets != form.type_tickets) {
      setForm_({
        company: '',
        contract: '',
        category: '',
        catalog: '',
      });
      setForm({
        fk_id_category: null,
        fk_id_catalog_service: null,
        fk_id_catalog_task: null,
      });
      catalogCompany(-1, -1);
    }
    if (prevCompany && prevCompany != form.fk_id_company) {
      setForm({ fk_id_contract: null });
      setForm_({
        company: '',
        contract: '',
        category: '',
        catalog: '',
      });
      setForm({
        fk_id_category: null,
        fk_id_catalog_service: null,
        fk_id_catalog_task: null,
      });
      catalogCompany(-1, -1);
    }
    if (prevCategory && prevCategory != form.fk_id_category) {
      setForm({ fk_id_catalog_service: undefined });
      setForm({ fk_id_catalog_task: undefined });
    }
    if (prevSubcategory && prevSubcategory != form.fk_id_catalog_service) {
      setForm({ fk_id_catalog_task: undefined });
    }

    setPrevTypeTickets(form.type_tickets);
    setPrevCompany(form.fk_id_company);
    setPrevCategory(form.fk_id_category);
    setPrevSubcategory(form.fk_id_catalog_service);
  }, [
    form.type_tickets,
    form.fk_id_company,
    form.fk_id_category,
    form.fk_id_catalog_service,
  ]);

  const formshtmlWithEdit = (t) => {
    const userLogin = Store.getUserLoged();
    const timezone = userLogin?.timezone?.value;
    const isRoleToEdit = userLogin.role === 'tech' ||
      userLogin.role === 'scm' || userLogin.scm ||
      userLogin.role === 'admin' || userLogin.role === 'client';

    /**
     * Added the timezone of the last user who updated the form.
     * This field is used to calculate the new time for the datetime field.
     */
    let data = [<input id="timezone-last-update-user" type="hidden" value={timezone} />];

    t.split('<div').map((i) => {
      const required = (i || '').indexOf('formbuilder-required') > -1;
      const labelMatch = (i || '').match(/label=\"([^\"]+)\"/);
      const label = labelMatch ? labelMatch[1] : undefined;
      const readOnly = (!isRoleToEdit || !i.includes('allow-editing-after-opening-record')) &&
        (currentCatalogTask === initialCatalogTask);

      if (i.indexOf('companies_var') > -1 || i.indexOf('fieldType="companies"') > -1) {
        let textInit = '';

        try {
          textInit =
            values && htmlValues
              ? htmlValues.find(
                (v) => v.name == 'companies_var'
              ).value
              : '';
        } catch (e) {
         }
        data.push(
          <div separator="true" id="companies_var" style={{ margin: '15px 0px 30px 0px', pointerEvents: readOnly? 'none': 'auto' }}>
            <CommonForm
              onChangeField={(field, value) => {
                // updateState({ companies_var: value });
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'companies_var',
                  label: label || language.COMPANY,
                  method: 'POST',
                  route: `${APIEndpoints.COMPANY}/filter`,
                  fieldquery: 'search',
                  textlabel: label || language.COMPANY,
                  textinit: textInit.length > 0 ? textInit : '',
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: textInit,
                  routeAll: `${APIEndpoints.COMPANY}/all?`,
                  methodGetAll: 'POST',
                  routeGetCount: `${APIEndpoints.COMPANY}/count`,
                  detail: true,
                  visible: true,
                  required,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_company',
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('locations_var') > -1) {
        let textInit = '';
        try {
          textInit = htmlValues.find((v) => v.name.includes('locations_var')).value;
        } catch (e) { }

        data.push(
          <div separator="true" id="locations_var" style={{ margin: '15px 0px 30px 0px', pointerEvents: readOnly ? 'none' : 'auto' }}>
            <CommonForm
              onChangeField={(field, value) => {
                // updateState({ locations_var: value });
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'locations_var',
                  label: label || language.LOCATION,
                  method: 'POST',
                  route: `${APIEndpoints.LOCATION}/filter`,
                  fieldquery: 'search',
                  textlabel: label || language.LOCATION,
                  textinit: textInit,
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  routeAll: `${APIEndpoints.LOCATION}/filter?`,
                  methodGetAll: 'POST',
                  detail: true,
                  visible: true,
                  required,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_location',
                  // value:
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('allusers_var') > -1 || i.indexOf('fieldType="allusers"') > -1) {
        let textInit = '';
        try {
          textInit =
            values && htmlValues
              ? htmlValues.find(
                (v) => v.name == 'allusers'
              ).value
              : '';
        } catch (e) { }
        data.push(
          <div separator="true" id="allusers_var" style={{ margin: '15px 0px 30px 0px', pointerEvents: readOnly ? 'none' : 'auto' }}>
            <CommonForm
              onChangeField={(field, value) => {
                // updateState({ allusers_var: value });
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'allusers',
                  label: '',
                  method: 'POST',
                  route: `${APIEndpoints.USER}/filter`,
                  fieldquery: 'search',
                  textlabel: label || language.USER,
                  textinit: textInit.length > 0 ? textInit : '',
                  fieldlabeldb: 'name',
                  idinit: label || language.USER,
                  routeAll: `${APIEndpoints.USER}/filter/all?`,
                  methodGetAll: 'POST',
                  routeGetCount: `${APIEndpoints.USER}/active/count/all`,
                  detail: true,
                  visible: true,
                  required,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_user',
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('hours_var') > -1) {
        let textInit = '';
        try {
          textInit =
            values && htmlValues
              ? htmlValues.find(
                (v) => v.name == 'hours_var'
              ).value
              : '';
        } catch (e) { }
        let nameField = 'hours_var';
        try {
          const regex = /<p(.*?)<\/p>/;
          const textWithoutTags = i.match(regex);
          nameField = textWithoutTags[1].split('|')[1];
        } catch (e) { }
        data.push(
          <div separator="true" id="hours_var" style={{ margin: '15px 0px 30px 0px', pointerEvents: readOnly ? 'none' : 'auto' }}>
            <CommonForm
              onChangeField={(field, value) => {
                // updateState({ hours: value });
              }}
              values={{
                [nameField]: textInit || null
              }}
              fields={[
                {
                  col: 12,
                  name: nameField,
                  label: language.HOURS,
                  type: 'time',
                  format: '##:##',
                  required
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('hidden_var') > -1) {
        let value_field = '';
        let name_field = '';
        try {
          const regex = /<p(.*?)<\/p>/;
          const textWithoutTags = i.match(regex);
          name_field = textWithoutTags[1].split('|')[1];
          value_field = textWithoutTags[1].split('|')[2];
        } catch (e) { }
        const html_field = `<div hidden><input value = ${value_field} name = ${name_field} /></div>`;
        data.push(<div separator="true" dangerouslySetInnerHTML={{ __html: html_field }} />);
      } else if (i.indexOf('departments_var') > -1 || i.indexOf('fieldType="departments"') > -1) {
        let textInit = '';
        try {
          textInit =
            values && htmlValues
              ? htmlValues.find(
                (v) => v.name == 'departments'
              ).value
              : '';
        } catch (e) { }
        data.push(
          <div separator="true" id="departments_var" style={{ margin: '15px 0px 30px 0px', pointerEvents: readOnly ? 'none' : 'auto' }}>
            <CommonForm
              onChangeField={(field, value) => {
                // updateState({ departments_var: value });
              }}git 
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'departments',
                  label: label || language.DEPARTMENTS,
                  textlabel: label || language.DEPARTMENTS,
                  method: 'POST',
                  route: `${APIEndpoints.DEPARTMENT}/filter/`,
                  routeGetCount: `${APIEndpoints.DEPARTMENT}/filter?count=0`,
                  fieldquery: 'search',
                  textinit: textInit.length > 0 ? textInit : '',
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: textInit.length > 0 ? textInit : '',
                  routeAll: `${APIEndpoints.DEPARTMENT}/filter?`,
                  methodGetAll: 'POST',
                  required,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_department',
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('subsdpt_var') > -1 || i.indexOf('fieldType="subsdpt"') > -1) {
        let textInit = '';
        try {
          textInit =
            values && htmlValues
              ? htmlValues.find(
                (v) => v.name == 'subdepartments'
              ).value
              : '';
        } catch (e) { }
        data.push(
          <div separator="true" id="subsdpt_var" style={{ margin: '15px 0px 30px 0px', pointerEvents: readOnly ? 'none' : 'auto' }}>
            <CommonForm
              onChangeField={(field, value) => {
                // updateState({ subdepartments_var: value });
                // setSubdptName(value);
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'subdepartments',
                  label: label || language.SUBDEPARTMENT,
                  textlabel: label || language.SUBDEPARTMENT,
                  method: 'POST',
                  filterAll: { fk_id_department: 1 },
                  filter: { fk_id_department: 1 },
                  route: `${APIEndpoints.DEPARTMENT}/filter/`,
                  routeGetCount: `${APIEndpoints.DEPARTMENT}/filter?count=0`,
                  fieldquery: 'search',
                  textinit: textInit.length > 0 ? textInit : '',
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: 0,
                  routeAll: `${APIEndpoints.DEPARTMENT}/filter?`,
                  methodGetAll: 'POST',
                  required,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_department',
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('contracts_var') > -1 || i.indexOf('fieldType="contracts"') > -1) {
        let textInit = '';
        try {
          textInit =
            values && htmlValues
              ? htmlValues.find(
                (v) => v.name == 'contracts_var'
              ).value
              : '';
        } catch (e) { }
        data.push(
          <div separator="true" id="contracts_var" style={{ margin: '15px 0px 30px 0px', pointerEvents: readOnly ? 'none' : 'auto' }}>
            <CommonForm
              onChangeField={(field, value) => {
                // updateState({ contracts_var: value });
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'contracts_var',
                  label: label || language.CONTRACTS,
                  method: 'POST',
                  route: `${APIEndpoints.CONTRACT}/filter`,
                  fieldquery: 'search',
                  textlabel: language.CONTRACTS,
                  textinit: textInit.length > 0 ? textInit : '',
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: 0,
                  routeAll: `${APIEndpoints.CONTRACT}/all?`,
                  methodGetAll: 'POST',
                  routeGetCount: `${APIEndpoints.CONTRACT}/count?`,
                  textlabel: language.CONTRACTS,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_contract',
                  required
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('chieftdpt_var') > -1 || i.indexOf('fieldType="chieftdpt"') > -1) {
        let textInit = '';
        try {
          textInit =
            values && htmlValues
              ? htmlValues.find((v) => v.name == 'boss_dpt')
                .value
              : '';
        } catch (e) { }
        data.push(
          <div separator="true" id="chieftdpt_var" style={{ margin: '15px 0px 30px 0px', pointerEvents: readOnly ? 'none' : 'auto' }}>
            <CommonForm
              onChangeField={(field, value) => {
                // updateState({ chieftdpt_var: value });
                // setChieftDptName(value);
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'boss_dpt',
                  label: label || language.DEPARTMENT_BOSS,
                  textlabel: label || language.DEPARTMENT_BOSS,
                  method: 'POST',
                  filter: { fk_id_company: 1 },
                  route: `${APIEndpoints.USER}/filter`,
                  routeGetCount: `${APIEndpoints.USER}/active/count/all`,
                  fieldquery: 'search',
                  textinit: textInit.length > 0 ? textInit : '',
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: textInit.length > 0 ? textInit : '',
                  routeAll: `${APIEndpoints.USER}/filter/all?`,
                  filterAll: 0,
                  methodGetAll: 'POST',
                  detail: true,
                  visible: true,
                  required,
                  disablePortal: true,
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('chiefsubdpt_var') > -1 || i.indexOf('fieldType="chiefsubdpt"') > -1) {
        let textInit = '';
        try {
          textInit =
            values && htmlValues
              ? htmlValues.find(
                (v) => v.name == 'boss_sub_dpt'
              ).value
              : '';
        } catch (e) { }
        data.push(
          <div separator="true" id="chiefsubdpt_var" style={{ margin: '15px 0px 30px 0px', pointerEvents: readOnly ? 'none' : 'auto' }}>
            <CommonForm
              onChangeField={(field, value) => {
                // updateState({ chiefsubdpt_var: value });
                // setChiefSubDptName(value);
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'boss_sub_dpt',
                  label: label || language.SUBDEPARTMENT_BOSS,
                  textlabel: label || language.SUBDEPARTMENT_BOSS,
                  method: 'POST',
                  filter: { fk_id_company: 1 },
                  filterAll: { chiefsub: true },
                  route: `${APIEndpoints.USER}/filter`,
                  routeGetCount: `${APIEndpoints.USER}/active/count/all`,
                  fieldquery: 'search',
                  textinit: textInit.length > 0 ? textInit : '',
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: textInit.length > 0 ? textInit : '',
                  routeAll: `${APIEndpoints.USER}/filter/all?`,
                  methodGetAll: 'POST',
                  required,
                  disablePortal: true,
                  idFieldToShow: 'id_department',
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('chief_var') > -1 || i.indexOf('fieldType="chief"') > -1) {
        let textInit = '';
        try {
          textInit =
            values && htmlValues
              ? htmlValues.find(
                (v) => v.name == 'all_boss_dpt'
              ).value
              : '';
        } catch (e) { }
        data.push(
          <div separator="true" id="chief_var" style={{ margin: '15px 0px 30px 0px', pointerEvents: readOnly ? 'none' : 'auto' }}>
            <CommonForm
              onChangeField={(field, value) => {
                // updateState({ chief_var: value });
                // setAllChieftName(value);
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'all_boss_dpt',
                  label: label || language.DEPARTMENT_BOSS,
                  textlabel: label || language.DEPARTMENT_BOSS,
                  method: 'POST',
                  filter: { fk_id_company: 1 },
                  filterAll: { all_chief: true },
                  route: `${APIEndpoints.USER}/filter`,
                  routeGetCount: `${APIEndpoints.USER}/active/count/all`,
                  fieldquery: 'search',
                  textinit: textInit.length > 0 ? textInit : '',
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: textInit.length > 0 ? textInit : '',
                  routeAll: `${APIEndpoints.USER}/filter/all?`,
                  methodGetAll: 'POST',
                  required,
                  disablePortal: true,
                  idFieldToShow: 'id_department',
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('departmentall_var') > -1 || i.indexOf('fieldType="departmentall"') > -1) {
        let textInit = '';
        try {
          textInit =
            values && htmlValues
              ? htmlValues.find(
                (v) => v.name == 'all_departments'
              ).value
              : '';
        } catch (e) { }
        data.push(
          <div separator="true" id="departmentall_var" style={{ margin: '15px 0px 30px 0px', pointerEvents: readOnly ? 'none' : 'auto' }}>
            <CommonForm
              onChangeField={(field, value) => {
                // updateState({ departmentall_var: value });
                // setAllDptName(value);
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'all_departments',
                  label: label || language.DEPARTMENTS,
                  textlabel: label || language.DEPARTMENTS,
                  method: 'POST',
                  route: `${APIEndpoints.DEPARTMENT}/filter/`,
                  routeGetCount: `${APIEndpoints.DEPARTMENT}/filter?count=0`,
                  fieldquery: 'search',
                  textinit: textInit.length > 0 ? textInit : '',
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: textInit.length > 0 ? textInit : '',
                  routeAll: `${APIEndpoints.DEPARTMENT}/filter?`,
                  methodGetAll: 'POST',
                  required,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_department',
                },
              ]}
            />
          </div>
        );
      } else if (i.indexOf('Multidepartment_var') > -1 || i.indexOf('fieldType="multidepartment"') > -1) {
        let nameField = '';
        const regex = /<p(.*?)<\/p>/;
        const textWithoutTags = i;
        nameField = textWithoutTags.split('"')[1];
        nameField = nameField.split('<')[0];

        let textInit = '';
        try {
          textInit =
            values && htmlValues
              ? htmlValues.find((v) => v.name == nameField)
                .value
              : '';
        } catch (e) { }
        data.push(
          <div separator="true" id={nameField} style={{ margin: '15px 0px 30px 0px', pointerEvents: readOnly ? 'none' : 'auto' }}>
            <CommonForm
              onChangeField={(field, value) => {
                // updateState({ departmentall_var: value });
                // let MD = multiDepartment;
                // MD.push({ index: nameField, value: value });
                // setMultiDepartment(MD);
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: `${nameField}`,
                  label: '',
                  textlabel: '',
                  method: 'POST',
                  route: `${APIEndpoints.DEPARTMENT}/filter/`,
                  routeGetCount: `${APIEndpoints.DEPARTMENT}/filter?count=0`,
                  fieldquery: 'search',
                  textinit: textInit,
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: textInit,
                  routeAll: `${APIEndpoints.DEPARTMENT}/filter?`,
                  methodGetAll: 'POST',
                  required,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_department',
                },
              ]}
            />
          </div>
        );
      } else {
        if (i.length > 1) {
          //é preciso eliminar quaisquer trechos que não sejam úteis, caso contrario, esse template ficará maior a cada vez que for editado na tela, podendo travar o navegador do usuário
          let g = '';
          if (i.indexOf('ql-tooltip ql-hidden') > -1) {
            g = `<div style = "display : none" ${i}`;
          } else if (
            i.indexOf('ql-preview') > -1 ||
            i.indexOf('ql-clipboard') > -1 ||
            i.indexOf('ql-snow') > -1
          ) {
            g = `<div  ${i}`;
          } else if (i.indexOf('ql-editor') > -1) {
            g = `<div style = "border: 1px solid #629daa80; min-height : 30px; border-radius : 0px 0px 10px 10px" ${i}`;
          } else if (
            i.indexOf('id="boss_dpt"') > -1 ||
            i.indexOf('id="boss_dpt-label"') > -1 ||
            i.indexOf('id="boss_sub_dpt"') > -1 ||
            i.indexOf('id="boss_sub_dpt-label"') > -1 ||
            i.indexOf('id="all_boss_dpt"') > -1 ||
            i.indexOf('id="all_boss_dpt-label"') > -1 ||
            i.indexOf('id="subdepartments"') > -1 ||
            i.indexOf('id="subdepartments-label"') > -1 ||
            i.indexOf('id="departments"') > -1 ||
            i.indexOf('id="departments-label"') > -1 ||
            i.indexOf('id="all_departments-label"') > -1 ||
            i.indexOf('id="all_departments"') > -1 ||
            i.indexOf('id="allusers_var"') > -1 ||
            i.indexOf('id="boss"') > -1 ||
            i.indexOf(' *</span>') > -1 ||
            i.indexOf('class="CommonForm2"') > -1 ||
            i.indexOf('Autocomplete') > -1 ||
            i.indexOf('hours_var') > -1 ||
            i.indexOf('Multidepartment_var') > -1 ||
            // i.indexOf('>Usuário<') > -1 ||
            //i.indexOf('>Horas</label>') > -1 ||
            i.indexOf('type="button"') > -1
          ) {
          } else {
            g = `<div class = "template-dinamic" ${i}`;
          }
          const div_with_no_children = /<div [^>]+>(.*?)<\/div>/g.exec(g);
          if (
            div_with_no_children &&
            div_with_no_children.length > 1 &&
            div_with_no_children[1] == ''
          ) {
            g = '';
          }

          g = g.replace(
            /<div class = "template-dinamic"  class="template-dinamic"><\/div><\/div>/g,
            ''
          );
          g = g.replace(/<div class = "template-dinamic" >/g, '');
          g = g.replace(/<div><\/div>/g, '');
          g = g.replace(/<div><\/div>/g, '');
          g = g.replace(
            /<div><div class="template-dinamic"><\/div><\/div>/g,
            ''
          );

          g = `<div separator="true">${g}</div>`

          const html = $('<div />', { html: g });
          const el = $(html).find('[name]');
          const elType = $(el).attr('type');
          const elName = $(el).attr('name');
          const elValue = htmlValues?.find((h) => h.name === elName)?.value;

          if (elType === 'time') {
            const hour = (elValue || '').split(':')[0];
            const min = (elValue || '').split(':')[1];
            const date = new Date(2000, 1, 1, hour, min, 0);

            if (isNaN(date) == false) {
              $(el).attr('value', moment(date).format('HH:mm'));
            }
          } else if (elType === 'datetime-local') {
            const date = new Date(elValue);
            elValue && $(el).attr('value', moment(date).format('YYYY-MM-DD HH:mm'));
          } else if (elType === 'radio') {
            const opt = $(el).parent().find('input[value="' + elValue + '"]');
            $(opt).attr('checked', true);
            $(el).parent().find('label').attr('for', $(el).attr('id') + '-recategorizacao');
            $(el).attr('id', $(el).attr('id') + '-recategorizacao');
            $(el).attr('name', elName + '-recategorizacao');
            $(el).attr('name-recategorizacao', elName);
          } else if (elType === 'checkbox') {
            const valuesCheckbox = htmlValues.filter((h) => h.name === elName);
            valuesCheckbox.forEach((v) => {
              const opt = $(el).parent().find('input[value="' + v.value + '"]');
              $(opt).attr('checked', 'checked');
            })
          } else if ($(el).is('select') && !$(el).is('select[multiple]')) {
            const escapedValue = elValue?.replace(/'/g, "&apos;");
            const opt = $(el).find(`option[value='${escapedValue}']`);
            $(opt).attr('selected', 'selected');
          } else if ($(el).is('textarea')) {
            let htmlString = $(el).prop('outerHTML');
            const valueToInsert = elValue !== undefined ? elValue : '';
            htmlString = htmlString.replace(/(<textarea[^>]*>)(<\/textarea>)/, `$1${valueToInsert}$2`);
            $(el).replaceWith(htmlString);
          } else if ($(el).is('select[multiple]')) {
            const valuesToSet = Array.isArray(elValue) ? elValue : [elValue];

            $(el).find('option').each(function() {
                const optionValue = $(this).val().replace(/'/g, "&apos;");
                const normalizedValuesToSet = valuesToSet?.map(value => {
                  return typeof value === 'string' ? value.replace(/'/g, "&apos;") : value;
                });

                if (normalizedValuesToSet.includes(optionValue)) {
                  $(this).attr('selected', 'selected');
                } else {
                  $(this).removeAttr('selected');
                }
            });
          } else {
            $(el).attr('value', elValue);
          }

          if (g.length > 0) {
            data.push(<div style={{ pointerEvents: readOnly ? 'none' : 'auto' }} dangerouslySetInnerHTML={{ __html: $(html).html() }} />);
          }
        }
      }
    });

    return data;
  };

  let company = form_.company || values.fk_id_company;

  form.recurrent = values.recurrent ? values.recurrent : form.recurrent;
  form.fk_id_request = values.fk_id_request ? values.fk_id_request : null;
  let contract = values.fk_id_contract ? values.fk_id_contract : form_.contract;

  let user = form_.user == '' ? values.fk_id_user : form_.user;

  let category =
    form_.category == '' ? values && values.fk_id_category : form_.category;

  let catalog =
    form_.catalog == ''
      ? values && values.fk_id_catalog_service
      : form_.catalog;

  let groutechfk = values.fk_id_group_tech
    ? values.fk_id_group_tech
    : form_.id_grouptech;

  let catalog_task =
    form_.catalog_task == '' ? values.fk_id_catalog_task : form_.catalog_task;

  let userSelect = []; // users.filter(c => c.id_user === user)[0];

  let br = sessionStorage.getItem('sensr_lang') == 'PT_BR';

  let contractOptions = [];

  let companyOptions = companies.map((c) => ({
    value: c.id_company,
    label: c.name,
  }));

  if (store.getUserLoged().role == 'solicitant') {
    companyOptions = [];

    contracts = contracts.filter((b) =>
      store.getUserLoged().contract.includes(b.id_contract),
    );
    let comp = [];

    contracts.map((b) => {
      comp.push(b.fk_id_out_company);
    });

    companyOptions = companies
      .filter((c) => comp.includes(c.id_company))
      .map((c) => ({ value: c.id_company, label: c.name }));
  }

  //retirei aqui
  if (form.fk_id_company == 1) {
    contractOptions = contracts
      .filter((c) => c.fk_id_company === form.fk_id_company || c.global == true)
      .map((ctr) => ({ value: ctr.id_contract, label: ctr.name }));
  } else {
    contractOptions = contracts
      .filter(
        (c) => c.fk_id_out_company === form.fk_id_company || c.global == true,
      )
      .map((ctr) => ({ value: ctr.id_contract, label: ctr.name }));
  }

  let techgroups = [];
  groupstech
    .filter((g) => g.fk_id_group_users == groutechfk)
    .map((tec) => techgroups.push({ value: tec.id_user, label: tec.name }));

  groupstech = groupstech.sort(function (a, b) {
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    return 0;
  });

  let techgroups_aux = [];
  groutechfk == 0
    ? groupstech
      .filter((g) => g.fk_id_group_users == form.fk_id_group_tech)
      .map((tec) => {
        solicitantOptions
          .filter((user) => user.value == tec.id_user && user.role === 'tech')
          .map((result) => {
            techgroups_aux.push({
              fk_id_group_users: form.fk_id_group_tech,
              id_user: result.id_user,
              name: (result.vip ? 'VIP - ' : '') + result.name,
            });
          });
      })
    : groupstech
      .filter((g) => g.fk_id_group_users == groutechfk)
      .map((tec) => {
        solicitantOptions
          .filter((user) => user.value == tec.id_user && user.role === 'tech')
          .map((result) => {
            techgroups_aux.push({
              fk_id_group_users: groutechfk,
              id_user: result.id_user,
              name: (result.vip ? 'VIP - ' : '') + result.name,
            });
          });
      });

  groupstech = techgroups_aux;

  // this.state.categoryOptions = categories.map(c => ({ value: c.id_category, label: c.name }));

  let tasksOptions = [];

  let t = [];

  let serviceCatalogOptions = [];

  let responsible = null;
  let priorityOptions = [];
  let urgencyOptions = [];

  let slaOptions = [];

  let impactsOptions = [];
  let idsla = [];
  let idpriority = [];
  let idurgency = [];
  let idimpact = [];
  let idcomplexity = [];

  if (values && values.fk_id_user && form_.devicesUser) {
    if (deviceOptions.length == 0) {
    }
  } else {
  }

  if (form_.device != '') {
    if (form_.fk_id_company == 1) {
      contracts
        .filter((c) => c.fk_id_company === company)
        .map((x) => {
          if (
            contractOptions.filter((g) => g.value == x.id_contract).length == 0
          )
            contractOptions.push({ value: x.id_contract, label: x.name });
        });
    } else {
      contracts
        .filter((c) => c.fk_id_out_company === company)
        .map((x) => {
          if (
            contractOptions.filter((g) => g.value == x.id_contract).length == 0
          )
            contractOptions.push({ value: x.id_contract, label: x.name });
        });
    }
  }

  try {
    if (category) {
      serviceCatalogOptions = catalogs.map((cat) => ({
        value: cat.id_catalog_service,
        label: cat.name,
      }));

      if (catalog) {
        if (catalogs.length > 0) {
          try {
            catalogs
              .filter((g) => g.id_catalog_service == catalog)[0]
              .task.map((t) => {
                tasksOptions.push({
                  value: t.id_catalog_task,
                  label:
                    t.name +
                    ' - ' +
                    t.description.replace(/<\/?[^>]+(>|$)/g, ''),
                });
              });
          } catch (e) { }
        }

        if (catalog_task === null || catalog_task == undefined) {
        } else {
          let taskcatalog = catalogs
            .filter((g) => g.id_catalog_service == catalog)[0]
            .task.filter((c) => c.id_catalog_task == catalog_task)[0];

          idurgency = [taskcatalog.id_urgency];

          idimpact = [taskcatalog.id_impact];

          idpriority = [taskcatalog.id_priority];

          idcomplexity = [taskcatalog.id_complexity];

          priorityOptions = priorities
            .filter((c) => c.id_table === idpriority[0])
            .map((pri) => ({ value: pri.id_table, label: pri.description }));

          urgencyOptions = urgencies
            .filter((c) => c.id_table === idurgency[0])
            .map((ur) => ({ value: ur.id_table, label: ur.description }));

          idsla =
            taskcatalog[0] != undefined &&
            taskcatalog.map((t) => t.time_sla)[0];

          // slaOptions = slas.filter(c => c.id_sla === idsla).map(sl => ({ value: sl.id_sla, label: sl.name }));
          slaOptions = slas.map((sl) => ({ value: sl.id_sla, label: sl.name }));

          impactsOptions = impacts
            .filter((c) => c.id_table === idimpact[0])
            .map((i) => ({ value: i.id_table, label: i.description }));
        }
      }
    }
  } catch (e) { }

  if (idpriority) {
    form.fk_id_priority = idpriority[0];
  }
  if (idurgency) {
    form.fk_id_urgency = idurgency[0];
  }

  if (idimpact) {
    form.fk_id_impact = idimpact[0];
  }

  if( idcomplexity ) {
   form.fk_id_complexity = idcomplexity[0];
  }

  if (catalog) {
    // form.fk_id_group_tech = form_.id_grouptech > 0 ? form_.id_grouptech : t[0];
    //groutechfk = form.fk_id_group_tech;
  }

  if (idsla) {
    form.fk_id_sla = idsla;
  }

  try {
    if (values.arraytk.length > 1) {
      values.ticketArray = values.arraytk;
    }
  } catch (e) {
    values.ticketArray = [];
  }

  if (deviceOptions.length == 1) {
    values.fk_id_device = deviceOptions[0].value;
    form.fk_id_device = deviceOptions[0].value;
  }

  return ticketavaliation == true ? (
    <Dialog
      onClose={onClose}
      title={language.PENDING_TICKET_LIST}
      maxWidth="lg"
      open={open}
    >
      <Avaliation
        store={store}
        action={action}
        APIEndpoints={APIEndpoints}
      ></Avaliation>
    </Dialog>
  ) : openKnowledge == true ? (
    <Dialog
      title={language.KNOWLEDGE_BASE}
      open={openKnowledge}
      maxWidth="lg"
      contentProps={{
        style: { marginLeft: -50, marginRight: -20 },
      }}
      onClose={() => setOpenKnowledge(false)}
    >
      <SolutionContainer subcategory={form.fk_id_catalog_service} />
    </Dialog>
  ) : (
    <Dialog
      PaperProps={{
        id: 'TicketManagementDialogForm',
      }}
      open={open}
      onClose={onClose}
      contentTitle={{
        style: { width: '100%' },
      }}
      btnCloseProps={{
        style: {
          marginTop: -88,
        },
      }}
      keepOpen={true}
      titleContentProps={{
        style: {
          width: 'calc(100% - 50px)',
        },
      }}
      maxWidth="xl"
      title={
        <div className="w-100 bar">
          <br></br>
          <div className="d-flex align-items-start">
            <div className="w-100" style={{ zoom: 0.8 }} zoom={0.8}>
              <CommonForm
                values={{
                  subject: form.subject,
                }}
                alert={false}
                fields={[
                  {
                    type: 'text',
                    name: 'subject',
                    label: language.TICKET_SUBJECT,
                    className: 'subject-ticket',
                    required: true,
                  },
                ]}
                onRef={(r) => setForms1(r)}
                onChangeField={(f, v) => setForm({ subject: v })}
              />
            </div>
          </div>
          <div className="d-flex"></div>
        </div>
      }
    >
      <div className="d-flex justify-content-space-between type-group">
        <div>
          <span
            style={{
              fontSize: 10,
              color: 'var(--primary)',
              fontWeight: 'bold',
            }}
          >
            {language.SELECT_TICKET_TYPE}
          </span>
          <div className="d-flex align-items-left btn-group">
            <Button
              onClick={() => setForm({ type_tickets: 4 })}
              icon="fas fa-user-plus"
              label={language.REQUEST}
              className="btn-item"
              outlined={crisisOption || form.type_tickets != 4}
              color={
                form.type_tickets == 4 && !crisisOption ? 'warning' : 'primary'
              }
              variant="normal"
            />
            <Button
              onClick={() => setForm({ type_tickets: 1 })}
              icon="fas fa-bell"
              label={language.TICKET_TYPES.INCIDENT}
              className="btn-item"
              outlined={crisisOption || form.type_tickets != 1}
              color={
                form.type_tickets == 1 && !crisisOption ? 'warning' : 'primary'
              }
              variant="normal"
            />

            <Button
              onClick={() => setForm({ type_tickets: 2 })}
              icon="fas fa-exclamation-triangle"
              style={{ borderLeft: 0 }}
              label={language.TICKET_TYPES.PROBLEM}
              className="btn-item"
              outlined={crisisOption || form.type_tickets != 2}
              color={
                form.type_tickets == 2 && !crisisOption ? 'warning' : 'primary'
              }
              variant="normal"
            />
            {generalParams.enable_event_ticket_type && (
              <Button
                onClick={() => setForm({ type_tickets: 5 })}
                icon="fas fa-clipboard"
                style={{ borderLeft: 0 }}
                label={language.TICKET_TYPES.EVENT}
                className="btn-item"
                outlined={crisisOption || form.type_tickets != 5}
                color={
                  form.type_tickets == 5 && !crisisOption
                    ? 'warning'
                    : 'primary'
                }
                variant="normal"
              />
            )}
            {enableTypeTicketCrisis && (
              <Button
                onClick={() => setForm({ type_tickets: 6 })}
                icon="fas fa-arrow-down"
                style={{ borderLeft: 0 }}
                label={language.TICKET_TYPES.CRISIS}
                className="btn-item"
                outlined={crisisOption ? false : form.type_tickets != 6}
                color={
                  form.type_tickets == 6 || crisisOption ? 'warning' : 'primary'
                }
                variant="normal"
              />
            )}
            {generalParams.enable_critical_incident_ticket_type && (
              <Button
                onClick={() => setForm({ type_tickets: 7 })}
                icon="fas fa-exclamation-circle"
                style={{ borderLeft: 0 }}
                label={language.TICKET_TYPES.CRITICAL_INCIDENT}
                className="btn-item"
                outlined={form.type_tickets != 7}
                color={form.type_tickets == 7 ? 'warning' : 'primary'}
                variant="normal"
              />
            )}
          </div>
          {values.ticketArray && values.ticketArray.length > 0 ? (
            <span
              style={{
                fontSize: 12,
                color: 'var(--primary)',
                fontWeight: 'bold',
              }}
            >
              {language.REQUESTS}{' '}
              {values.ticketArray.map((h) => 'R' + h).join(' ')}
            </span>
          ) : null}
        </div>
        <div className="d-flex align-items-rigth btn-group">
          <Button
            variant="normal"
            icon={<KnowledgeBase style={{ fill: 'white', width: 20 }} />}
            label={language.KNOWLEDGE_BASE}
            color="warning"
            style={{ marginTop: 27 }}
            fluid
            onClick={
              () => setOpenKnowledge(true) /*redirect('/dash/solution')*/
            }
          />
        </div>
      </div>
      <hr />
      <div style={{ zoom: 0.9 }} zoom={0.9}>
        <Tooltip
          style={{ marginLeft: '65%' }}
          interactive
          html={
            <div style={{ width: 350 }}>
              <GC
                valueProps={{
                  className: 'd-flex align-items-center',
                }}
                items={[
                  {
                    label: language.EMAIL,
                    value: userDetail.email,
                  },
                  {
                    label: language.NAME,
                    value: userDetail.name,
                  },
                  {
                    label: language.DEPARTMENT,
                    value: userDetail.department,
                  },
                  {
                    label: language.PHONE,
                    value: userDetail.phone,
                  },
                ]}
              />
            </div>
          }
          arrow={true}
          position="left"
          theme="light"
        >
          <i style={{ color: '#019fc2' }} class="fas fa-info"></i>
        </Tooltip>
        <CommonForm
          spacing={3}
          values={form}
          alert={false}
          forceUpdate={true}
          updateStateForm={true}
          fields={[
            /* {
              span: 12,
              type: 'autocomplete',
              name: 'fk_id_group_tech',
              textlabel: `Grupo Técnico`,
              className: 'tech-group-ticket',
              required: true,
              filterAll: { type_search: 1, sub_tickets_out : true },
              method: 'POST',
              route: `${APIEndpoints.GROUP}/filter`,
              fieldquery: 'search',
              textinit:  '',
              idinit: values && values.fk_id_group_tech ? values.fk_id_group_tech : 0,
              fieldvaluedb: 'id_group_users',
              fieldlabeldb: 'name',
              useinit: true,
              routeAll: `${APIEndpoints.GROUP}/all?`,
              detail: true,
              methodGetAll: 'POST',
              routeGetCount: `${APIEndpoints.GROUP}/count`,
              textinit: values && values.config[0] ? values.config[0].grouptech : 'Grupo Técnico',
              textlabel: 'Grupo Técnico',
              visible : subTicketCreated ? true : false
            },*/
            {
              col: 4,
              type: 'autocomplete',
              name: 'fk_id_company',
              label: language.COMPANY,
              method: 'POST',
              route: `${APIEndpoints.COMPANY}/filter`,
              fieldquery: 'search',
              textlabel: language.COMPANY,
              textinit: values.company_name,
              fieldvaluedb: 'id_company',
              fieldlabeldb: 'name',
              idinit: values.fk_id_company,
              routeAll: `${APIEndpoints.COMPANY}/all?`,
              methodGetAll: 'POST',
              routeGetCount: `${APIEndpoints.COMPANY}/count`,
              detail: true,
              visible: values.ticketArray.length > 1 ? false : true,
              required: true,
            },

            {
              col: 4,
              type: 'select',
              name: 'fk_id_contract',
              label: language.CONTRACT_MANAGEMENT,
              options: contractOptions,
              required: true,
            },
            {
              col: 4,
              type: 'select',
              name: 'fk_id_device',
              label: language.DEVICE,
              visible: values.ticketArray.length > 1 ? false : true,
              options: deviceOptions,
              required: values.ticketArray.length > 1 ? false : false,
            },
            {
              col: 4,
              type: 'select',
              name: 'fk_id_category',
              options: categories
                .filter(
                  (c) =>
                    c.type_ticket && c.type_ticket.includes(form.type_tickets),
                )
                .map((ctr) => ({ value: ctr.id_category, label: ctr.name })),
              label: language.CATEGORY,
              required: true,
            },
            {
              col: 4,
              type: 'select',
              name: 'fk_id_catalog_service',
              label: language.SUBCATEGORY,
              options: serviceCatalogOptions,
              required: true,
            },
            {
              col: 4,
              type: 'select',
              name: 'fk_id_catalog_task',
              label: language.THIRD_LEVEL_CATEGORY,
              options: tasksOptions,
              required: true,
            },
            {
               col: 6,
               name: 'dt_start_crisis',
               label: language.SOLICITATION_DATE,
               type: 'date',
               required: true,
               visible: form.type_tickets == 6,
             },
             {
               col: 6,
               name: 'dt_starttime_crisis',
               label: language.SOLICITATION_HOUR,
               type: 'time',
               required: true,
               visible: form.type_tickets == 6,
             },
          ]}
          onRef={(r) => setForms3(r)}
          onChangeField={(f, v) => {
            if (f.name == 'fk_id_user') {
              userDetailFunc(v);
              deviceContractSolicitant(undefined, v);
              setCleanValueSolicitant(false);
              chiefDepartment(v);
            }

            if (f.name == 'fk_id_contract') {
              setForm_({ contract: v });
              deviceContractSolicitant(v, values.fk_id_user);
              // chiefDepartment(form_.user);
            }

            if (f.name == 'fk_id_company') {
              setForm_({ company: v });
              setCompanyId(v);
              //  if (store.getUserLoged().role != 'client') {
              catalogCompany(v);
              categoryCompany(v);

              setForm({ fk_id_company: v });

              // setForm({ fk_id_user: undefined });
              setCleanValueSolicitant(true);
              /*} else {
                catalogCompany(undefined);
                categoryCompany(undefined);
              }*/
              companyallContract(v);
            }

            if (f.name == 'fk_id_device') {
              setForm_({ device: v });
            }

            if (f.name == 'fk_id_category') {
              setForm_({ category: v });
              catalogCompany(companyId, v);
            }

            if (f.name == 'fk_id_catalog_service') {
              setForm_({ catalog: v });
              catalogGroup(v);
            }

            if (f.name == 'fk_id_catalog_task') {
              currentCatalogTask = v;
              setForm_({ catalog_task: v });
              getFormCatalog(v);
            }
            if (f.name == 'fk_id_group_tech') {
              setForm_({ id_grouptech: v });
            }
            if (f.name == 'fk_id_department') {
              setForm_({ iddep: v });
            }
            if (f.name == 'recurrent') {
              setForm({ recurrent: v });
            }
            setForm({ [f.name]: v });

            if (f.name == 'fk_id_user' && v) {
              chiefDepartment(v);
            }
          }}
        />
      </div>

      <hr />
      {formsTemplate && (
        <div id="template-recategorization" style={{ marginTop: '10px' }} className="template">
          {formsTemplate}
        </div>
      )}
      <hr />
      <Button
        icon="fas fa-save"
        variant="normal"
        color="success"
        fluid
        onClick={() => (ds == true ? onFormSubmitTab_() : null)}
        loading={store.$btn_loading('post' + '-' + APIEndpoints.TICKET)}
        style={{ height: 40 }}
        label={language.SAVE}
      />
    </Dialog>
  );
}
